@font-face {
  font-family: HKGrotesk;
  src: url("/fonts/hk-grotesk/HKGrotesk-Medium.otf") format("opentype");
}

@font-face {
  font-family: HKGroteskBold;
  font-weight: bold;
  src: url("/fonts/hk-grotesk/HKGrotesk-Bold.otf") format("opentype");
}

@font-face {
  font-family: HKGroteskLight;
  font-weight: lighter;
  src: url("/fonts/hk-grotesk/HKGrotesk-Light.otf") format("opentype");
}


* {
  outline: none !important;
}
body{
  font-family: 'HKGrotesk';
  color: #222;
}
a, a:hover{
  text-decoration: none;
  color: #333;
}

#root:empty {
  position: absolute;
  width: 100%;
  height: 100%;

  animation: pulsating 1.2s infinite linear;
  /* background: url('/bearded-logo.png') center center no-repeat; */
  background: url('/bearded-logo.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 124px 70px;
}

@keyframes pulsating {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}


.album__container {
  margin-left: -5px; 
  margin-right: -5px;
}
.imageBox {
  overflow: hidden;
  box-sizing: border-box;
  background-color: #ccc;
  background-size: cover;
  background-position: center center;
  margin-left: 5px; 
  margin-right: 5px;
  margin-bottom: 10px;
  position: relative;
}

.imageBox img{
  visibility: hidden;
}

.box-1 {
  width: calc(25% - 10px);
}
.box-2 {
  width: calc(50% - 10px);
}
.box-3 {
  width: calc(75% - 10px);
}
.box-4 {
  width: calc(100% - 10px);
}


.home-project__mask{
  position: absolute;
  z-index: 300;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  
  transition: background 0.4s ease-in-out;
}

.home-project__mask.filter-out,
.imageBox:hover .home-project__mask.home-project__mask.filter-out{
  background: #FFA700;
  visibility: visible;
}

.home-project__mask.filter-out .home-project__overlay{
 visibility: hidden;
}

.imageBox:hover .home-project__mask{
  visibility: visible;
  background-color: rgba(0,0,0, .75);
}

.home-project__overlay{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.home-project__subtitle {
  font-size: 14px;
}
.home-project__name {
  font-size: 28px;
  font-family: Garamond;
  font-weight: bold;
  line-height: 110%;
}
.home-project__overlay p{
  margin-bottom: 0;
}
.home-project__overlay hr{
  width: 20%;
  height: 0px;
  margin: 0 auto;
  border-top: 1px solid #fff;
  display: none;
}







ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
li {
  display: inline;
}



.header {
  padding: 25px 0px;
}
.header__filters {
  padding-top: 20px;
  text-align: right;
}
.header__filters ul{
  display: block;
  float: right;
  text-align: left;
}

.header li:not(:first-child) {
  margin-left: 20px;
}

.header__text h1, .project-title h2{
  font-family: 'Garamond';
  text-align: center;
  font-weight: 600;
  font-size: 45px;
}

.header__menu {
  padding-top: 15px;
}
.header__menu a,
.header__filters a{
  color: #4d4d4d;
}
.header__menu a{
  font-size: 20px;
}
.header__filters a{
  font-size: 16px;
}


.underline {
  text-decoration: none; 
  position: relative; 
  cursor: pointer;
}   
.underline:hover, .underline.active {
  color: #FFA700 !important;
}

.underline:after {
    position: absolute;
    content: '';
    height: 2px;
    /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
    bottom: -5px; 

    left: 0;
    right: 0;
    width: 0%;
    background: #FFA700;

    /* optional animation */
    -o-transition:.35s;
    -ms-transition:.35s;
    -moz-transition:.35s;
    -webkit-transition:.35s;
    transition:.35s;
}


/* optional hover classes used with animation */
.underline:hover:after, .underline.active:after {
 width: 100%;
}

/* @media (min-width: 1350px){
  .container {
      max-width: 1390px;
  }
  .header__menu a{
    font-size: 25px;
  }
  .header__filters a{
    font-size: 20px;
  }
}
@media (min-width: 1191px){
  .container {
      max-width: 1160px;
  }
  .header__menu a{
    font-size: 22px;
  }
  .header__text h1{
    font-size: 36px;
  }
  .header__filters a{
    font-size: 18px;
  }
} */

.footer__text {
  margin: 50px -15px;
}

.footer__pride {
  font-size: 24px;
  line-height: 1.45;
  font-family: HKGroteskLight;
  padding-right: 30px;
}
.footer__pride--orange {
  font-size: 20px;
  font-weight: bold;
  color: #FFA700;
  padding-bottom: 4px;
  border-bottom: 2px solid #FFA700;
}
.footer__title {
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}


.footer__social:after{
    content: "";
    display: table;
    clear: both;
}

.footer__social a{
  display: inline-block;
  position: relative;
  width: 1.4em;
  height: 1.4em;
  background: #FFA700;
  border-radius: 50%;
  margin-right: 10px;
}
.footer__social a i{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 12px;
}
.footer__slides {
  padding-bottom: 50px; 
}


.footer__signature {
  text-align: center;
  padding: 40px;
}
.footer__signature h3 {
  font-size: 28px;
  font-family: Garamond;
  font-weight: 700;
}
.footer__signature p {
  font-size: 14px;
  font-family: HKGroteskLight;
}

.image-gallery-bullets {
  bottom: -30px;
}
.image-gallery-bullets .image-gallery-bullet{
  border: 1px solid #FFA700;
  box-shadow: none;
  padding: 4px;
  margin: 0 8px;
}
.image-gallery-bullets .image-gallery-bullet.active{
  background: #FFA700;
}
.image-gallery-left-nav, .image-gallery-right-nav {
  font-size: 2.5em;
}
.image-gallery-fullscreen-button::before, .image-gallery-play-button::before, .image-gallery-left-nav::before, .image-gallery-right-nav::before{
  text-shadow: 0 1px 1px #555;
}
.image-gallery-fullscreen-button:hover::before, .image-gallery-play-button:hover::before, .image-gallery-left-nav:hover::before, .image-gallery-right-nav:hover::before{
  color: #FFA700;
}

.project-header{
  position: relative;
  margin-bottom: 10vh;
}
.project-header .spike{
  height: 8vh;
  width: 1px;
  border-left: 1px solid #fff;
  position: absolute; 
  bottom: 0;
  left: 50%;
  z-index: 250;
}
.project-header .spike.black{
  bottom: -8vh;
  border-color: #000;
}

.project-cover {
  width: 100%;
  height: auto;
  max-height: 80vh;
  overflow: hidden;
  position: relative;
  margin-bottom: 50px;
}
.project-cover img {
  width: 100%;
  height: auto;
} 
.project-cover .project-cover__overlay{
  z-index: 110;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, .55);
}
.project-cover .project-title{
  position: absolute;
  z-index: 120;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.project-cover .header__text,
.project-cover .header__text a  {
  color: #fff;
  text-align: center;
  padding-top: 20px;
}
.project-cover .project-title > p {
  font-family: HKGroteskLight;
  text-align: center;
  color: #ddd;
}
.project-cover .header__text h1{
  font-size: 28px;
}


.vimeo-wrapper {
  position: relative;
  padding-bottom: 54%;
  /* almost 16:9 */
  padding-top: 25px;
  height: 0; 
}

.vimeo-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.image-gallery-image .play-button {
  position: absolute;
  z-index: 200;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  color: #FFA700;
  text-shadow: 0 2px 2px #333;
  font-size: 60px;
  cursor: pointer;
}

.video-gallery{
  padding: 50px 100px; 
}
.video-gallery__nav{
  text-align: center;
  padding: 20px;
  font-size: 14px;
}
.video-gallery__nav li:not(:last-child){
  margin-right: 30px;
}

.project-section{
  margin-bottom: 50px;
}
.project-section__title {
  position: relative;
  padding-left: 100px; 
  margin-bottom: 20px;
}
.project-section__title.with-left-line:before{
  content: '';
  width: 40px;
  position: absolute; 
  left: 0;
  top: 50%;
  border-top: 2px solid #000;
}
.project-section__title p{
  position: absolute;
  font-family: HKGroteskLight; 
  top: -20px;
  font-size: 12px;
  margin: 0;
}
.project-section__title h3{
  font-family: Garamond;
  font-weight: bold;
  font-size: 24px;
  margin: 0;
}
.project-section__content{
  padding: 50px 100px; 
  font-family: HKGroteskLight;
  font-size: 18px;
}

.project-section__testimonial{
  padding: 50px 100px;
}
.project-section__testimonial .project-section__text,
.project-section__testimonial .project-section__author {
  text-align: center;
  font-size: 22px;
  font-family: HKGroteskLight;
  padding-bottom: 20px;
}

.project-section__credits{
  padding: 50px 100px; 
}
.project-section__credits hr{
  width: 50px;
  height: 0px;
  margin: 25px auto;
  border-top: 1px solid #333;
}
.project-section__credits h3{
  font-family: Garamond;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}
.project-section__credits .credit-line{
  width: 300px;
  /* background: yellow; */
  margin: 20px auto;
}

.credit-line .credit-part{
  font-weight: bold;
  font-family: Garamond;
}
.credit-line .credit-name{
  font-family: HKGroteskLight;
}



.circle-plus.closed .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(-90deg);
}
.circle-plus.closed .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(-90deg);
  opacity: 1;
}

.circle-plus {
  height: 4em;
  width: 4em;
  font-size: 1em;
  opacity: 1;
  cursor: pointer;
}

.circle-plus.opened {
  opacity: 1;
}
.circle-plus.opened .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
}
.circle-plus.opened .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
  opacity: 0;
}

.circle-plus .circle {
  position: relative;
  width: 2.55em;
  height: 2.5em;
}

.circle-plus .circle .horizontal {
  position: absolute;
  background-color: #FFA700;
  width: 30px;
  height: 5px;
  left: 50%;
  top: 50%;
  margin-left: -15px;
  margin-top: -2.5px;
}

.circle-plus .circle .vertical {
  position: absolute;
  background-color: #FFA700;
  width: 5px;
  height: 30px;
  left: 50%;
  top: 50%;
  margin-left: -2.5px;
  margin-top: -15px;
}
.project-description > .circle-plus{
  transform: scale(0.7);
}
.circle-plus .circle{
  margin-left: -20px;
}


.project-description__extended{
  overflow: hidden;
}
.project-description__extended--hidden {
  height: 0px;
  transition: all 0.3s;
}
.project-description__extended--visible {
  height: 100%;
  transition: all 0.3s;
}


.isMobile .project-section {
  margin-top: 40px;
  margin-bottom: 30px; 
}

.isMobile .project-section__title p{
  font-size: 14px;
}
.project-section__title h3{
  font-size: 28px;
}
.isMobile .project-section--description .project-section__title{
  padding-left: 0;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 20px;
}
.isMobile .project-section--description .project-section__title.with-left-line:before{
  left: 50%;
  width: 25px;
  top: -50px;
  transform: translateX(-50%);
}
.isMobile .project-section--description .project-section__title p{
  top: -20px;
  text-align: center;
  width: 100%;
}
.isMobile .project-description {
  font-size: 16px;
}


.isMobile .project-section__title{
  padding-left: 0;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
}
.isMobile .project-section__title.with-left-line:before{
  
  width: 25px;
  left: 50%;
  top: calc(100% + 10px);
  transform: translateX(-50%);
}
.isMobile .project-section__title p{
  top: -20px;
  text-align: center;
  width: 100%;
}





.isMobile .project-section__content{
  padding: 10px;
  text-align: center;
}
.isMobile .project-description > .circle-plus{
  display: inline-block;
}
.isMobile .project-description > .circle-plus{
  transform: scale(0.6, 0.6);
}




@media only screen and (max-width: 600px) {

  .row {
    margin-right: 0;
    margin-left: 0;
  }
  .header__menu{
    text-align: center;
    padding-top: 5px;
  }
  .header__filters{
    display: none;
  }
  .header__text, .header__menu{
    width: 100%;
  }
  .header__text h1, .project-title h2{
    font-size: 36px;
  }

  .imageBox {
    margin-left: 0;
    margin-right: 0;
    border-radius: 5px;
  }
  .box-1, .box-2, .box-3, .box-4{
    width: 100%;
    max-height: 200px;
  }

  .home-project__mask{
    visibility: visible;
    background-color: rgba(0,0,0, .55);
  }

  .home-project__overlay hr{
    display: block;
    margin: 10px auto 5px auto;
    width: 25px;
    border-top: 2px solid #fff;
  }

  .footer{
    text-align: center;
  }

  .footer__pride {
    font-size: 18px;
    padding-right: 0;
    font-weight: bold;
    line-height: 32px;
  }

  .footer__pride, 
  .footer__start-project,
  .footer__connect,
  .footer__start-project,
  .footer__findus {
    padding-top: 15px;
    padding-bottom: 15px;
  } 

  .footer__social a {
    width: 2em;
    height: 2em;
  }
  .footer__social a i {
    font-size: 16px;
  }
  .footer__slides .image-gallery-slides{
    border-radius: 5px;
    overflow: hidden;
  }
  .footer__signature{
    padding: 20px;
  }

  .video-gallery, .video-gallery__nav{
    padding: 0;
  }
  .video-gallery__nav{
    font-size: 12px;
    padding-top: 10px;
    color: rgba(0,0,0, .45);
  }

  .project-section__testimonial{
    padding: 10px 20px;
  }

  .project-section__testimonial .project-section__text, 
  .project-section__testimonial .project-section__author{
    font-size: 16px;
  }

  .project-section__credits{
    padding: 10px;
  }
  .project-section__credits .credit-line .credit-part{
    text-align: right;
  }
  .project-section__credits hr {
    border-top: 2px solid #000;
    width: 25px;
  }
  .project-section__credits .project-section__title{
    margin-top: 0;
  }
  
}